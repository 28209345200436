<template>
  <div class="p-1">
    <div
      class="relative w-full px-4 max-w-full flex-grow flex-1 mt-10 mb-5 text-right"
    >
      <div
        class="border hover:border-green-500 w-full focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
      >
        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
          <p>
            <label for="status" class="bg-white text-gray-700 px-1"
              >{{ $t("date") }} :
              <span :class="$colors.required">*</span></label
            >
          </p>
        </div>
        <p>
          <select
            id="type"
            autocomplete="type"
            @change="ChangeTypeDate($event)"
            v-model="timeserch"
            class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
          >
            >
            <option class="bg-white text-gray-600" value="today">
              {{ $t("today") }}
            </option>
            <option class="bg-white text-gray-600" value="thisweek">
              {{ $t("this_week") }}
            </option>
            <option class="bg-white text-gray-600" value="lastweek">
              {{ $t("last_week") }}
            </option>
            <option class="bg-white text-gray-600" value="thismonth">
              {{ $t("this_month") }}
            </option>
            <option class="bg-white text-gray-600" value="lastmonth">
              {{ $t("last_month") }}
            </option>
            <option class="bg-white text-gray-600" value="thisyear">
              {{ $t("this_year") }}
            </option>
          </select>
        </p>
      </div>
    </div>
    <div-table
      :config="configList"
      :data="content"
      :emailCurrentCall="emailCurrentCall"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      @refresh="refresh"
      @downloadAnalytics="downloadAnalytics"
    ></div-table>
  </div>
</template>

<script>
import config from "./configStatus";
export default {
  data() {
    return {
      configList: config.list,
      loading: false,
      content: [],
      Allcontent: [],
      currentUser: {},
      limit: 10,
      timeserch: "today",
      emailCurrentCall: "",
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
    };
  },

  async mounted() {
    await this.getUser();
    await this.GetCurrentCallcenter();
    await this.getStatusHistories({ limit: this.limit });
    await this.getAllStatusHistories();
  },
  methods: {
    async downloadAnalytics() {
      var arr =
        typeof this.Allcontent !== "object"
          ? JSON.parse(this.Allcontent)
          : this.Allcontent;

      for (let i in arr) {
        arr[i].Email = arr[i].user.email;
        arr[i].dateDebut = this.$moment(arr[i].dateDebut).format(
          "yyyy-MM-DD HH:mm"
        );
        arr[i].previousStatus = arr[i].previousStatus.name;
        arr[i].status = arr[i].status.name;
      }

      for (let i in arr) {
        delete arr[i].user;
        delete arr[i]._id;
        delete arr[i].__v;
        delete arr[i].isDeleted;
        delete arr[i].fees;
        delete arr[i].order;
        delete arr[i].comment;
        delete arr[i].date;
      }

      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);
      var element = document.createElement("a");
      element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
      element.target = "_blank";
      element.download = "exportCallcenter_Statistics.csv";
      element.click();
      await this.getAllStatusHistories();
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async ChangeTypeDate(event) {
      this.timeserch = event.target.value;
      this.content = [];
      await this.getStatusHistories({ limit: this.limit });
      await this.getAllStatusHistories();
    },
    async GetCurrentCallcenter() {
      const filter = {
        _id: this.$route.params.id,
      };
      const res = await this.$server.search("users", filter);
      if (res.content) {
        this.emailCurrentCall = res.content.results[0].email;
      }
    },
    async getStatusHistories(filters) {
      filters.user = this.$route.params.id;
      filters.DateType = this.timeserch;
      const res = await this.$server.search("statusHistories", filters);
      if (res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else this.content = [];
    },
    async getAllStatusHistories() {
      const filters = {
        user: this.$route.params.id,
        DateType: this.timeserch,
      };
      const res = await this.$server.search("statusHistories", filters);
      if (res.content.results) {
        this.Allcontent = res.content.results;
      } else this.Allcontent = [];
    },
    async refresh() {
      this.getStatusHistories({ limit: this.limit });
      await this.getAllStatusHistories();
    },

    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: this.limit,
      };
      this.getStatusHistories(filters);
    },
  },
};
</script>
